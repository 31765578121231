import React from "react"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import BEEPage from "../components/BEEPage"

const BEE = () => {
  return (
    <>
      <SEO title="BEE 123" />
      <Navbar back bottomOffset={125} />
      <BEEPage />
      <Footer back />
    </>
  )
}

export default BEE
