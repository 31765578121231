/**
 * @flow
 */

import React from "react"
import styled from "styled-components"

import media from "../../styles/media"
import PageHeader from "./header"
import Label from "../elements/label"

const Container = styled.div`
  &.blue {
    background-color: rgba(0, 125, 197, 0.99);
  }
  &.black {
    background-color: rgba(63, 63, 63, 0.99);
  }
  &.p-left {
    padding-left: var(--gutter-s);
  }
  &.p-left-l {
    padding-left: var(--gutter-l);
  }
  &.p-right {
    padding-right: var(--gutter-s);
  }
  &.p-top {
    padding-top: 5vh;
  }
  &.p-bottom {
    padding-bottom: 5vh;
  }
  ${media.tablet`
    &.p-left {
      padding-left: 10vw;
    }
    &.p-left-l {
      padding-left: 10vw;
    }
    &.p-right {
      padding-right: 5vw;
    }
    &.p-top {
      padding-top: 5vh;
    }
    &.p-bottom {
      padding-bottom: 5vh;
    }
  `}
  ${media.laptop`
    &.p-left {
      padding-left: 25vw;
    }
    &.p-left-l {
      padding-left: 25vw;
    }
    &.p-right {
      padding-right: 20vw;
    }
  `}
`

const Intro = styled.div`
  padding-top: 2rem;
  p {
    color: rgba(255, 255, 255, 0.8);
    font-family: "butlermedium";
    line-height: 1.75rem;
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  ${media.tablet`
  `}
`

const Contact = styled.div`
  p {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.75rem;
    font-size: 1rem;
    margin-bottom: 2rem;
    font-family: "AvenirLTStdBlack";
  }
  ${media.tablet`
  `}
`

const Mailto = styled.a`
  position: relative;
  font-family: "AvenirLTStdBlack";
  padding-top: 0.45rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: inline-block;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
`

const BEEPage = () => {
  return (
    <>
      <Container className="blue p-left-l p-bottom">
        <PageHeader />
      </Container>
      <Container className="blue p-left p-right p-bottom">
        <Intro>
          <Label
            fontSize={1}
            outerRotate={0}
            innerRotate={-1}
            value="Sound Idea Sessions and Sage BEE123 have joined forces in"
          />
          <Label
            mLeft={1}
            fontSize={1}
            outerRotate={0}
            innerRotate={0}
            value="giving you the oppertunity to sponsor tickets for start-ups,"
          />
          <Label
            fontSize={1}
            outerRotate={0}
            innerRotate={-1}
            value="as part of your Enterprise and Supplier Development initiatives"
          />
          <br />
          <br />
          <br />
          <p>
            Growth Hacking speaks to a good mix of delegates - from aspiring
            start-ups who need new-age tactics out of necessity to corporates
            who are starting to realise the limits of traditional marketing.
            Here’s the thing, budget-strapped start-ups, don’t always have the
            cash for these kinds of learning opportunities.
          </p>
          <p>
            In true growth hacker style, Ryan Holiday came up with an innovative
            way to get start-ups in seats, and give corporations an opportunity
            to pay it forward. We’re giving corporates the chance to sponsor
            tickets for start-ups, as part of their Enterprise and Supplier
            Development Initiatives.
          </p>
          <p>
            To make it work, we’ve partnered with BEE123. This company provides
            a revolutionary, cutting edge solution, ensuring that B-BBEE is easy
            to understand, plan, assess and implement, and that meaningful
            transformation can be achieved. Through their unique access to
            pre-qualified Enterprise Development Entrepreneurs, corporates can
            make a real difference, by providing Sound Idea Sessions tickets to
            these individuals. They’ll also ensure that your good deed doesn’t
            just impact entrepreneurs, but also your B-BBEE scorecard, by
            processing the necessary documentation to ensure your business
            receives the B-BBEE points.
          </p>
          <p>
            As a little ‘thank you’ from us, ticket sponsors will also get
            visibility at the event, be included in press releases and social
            media posts, and have their logos displayed below.
          </p>
        </Intro>
      </Container>
      <Container className="black p-left p-right p-bottom p-top">
        <Contact>
          <p>
            If you’re as dedicated as us to developing entrepreneurs and
            assisting in further transformation in our country, let’s talk.
          </p>
          <Mailto
            href="mailto:ed@soundideasessions.com?subject=Sage BEE123 Enterprise Development"
            className="mouse-link"
          >
            Get In Touch With Us &#8594;
          </Mailto>
        </Contact>
      </Container>
    </>
  )
}

export default BEEPage
