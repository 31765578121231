/**
 * @flow
 */

import React from "react"
import styled from "styled-components"

import media from "../../styles/media"

type Props = {
  leftIndent?: boolean,
}

const Container = styled.div`
  padding-left: ${props => (props.leftIndent ? "var(--gutter-l)" : "0")};
  svg {
    enable-background: new 0 0 490.3 128.8;
    .st0 {
      fill: rgba(255, 255, 255, 0.95);
    }
  }
  ${media.tablet`
    padding-left: ${props => (props.leftIndent ? "0" : "0")};
  `}
  ${media.laptop`
    padding-left: ${props => (props.leftIndent ? "0" : "0")};
  `}
`

const PageHeader = ({ leftIndent }: Props) => {
  return (
    <Container leftIndent={leftIndent}>
      <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 490.3 128.8">
        <g>
          <path
            className="st0"
            d="M42.3,108.1c0.2,16.8-13.2,20.3-24.7,20.5l-17,0.2l0-0.5c2.5,0,3.3-3.5,3.3-7.4L3.5,96.2
		c-0.1-3.6-1-7.5-3.5-7.4l0-0.5L17,88C28.5,87.9,42.1,91.2,42.3,108.1z M27.9,108.3c-0.3-18.4-3.7-19.9-10.8-19.8l-2.5,0l0.6,39.5
		l2.5,0C24.4,128,28.2,127.1,27.9,108.3z"
          />
          <path
            className="st0"
            d="M80.5,116.4l0.2,11.3L47,128.1l0-0.5c2.4,0,3.3-3.5,3.2-7.4l-0.4-24.7c-0.1-3.6-0.9-7.5-3.4-7.4l0-0.5
		l32.9-0.5l0.2,11.8l-0.5,0c-0.2-11.2-15.9-11.1-15.9-11.1l-2.1,0l0.3,19.3l4.7-0.1c0.1,0,6.2,0.1,6.7-6.9l0.5,0l0.2,14.1l-0.5,0
		c-0.7-6.9-6.8-6.7-6.9-6.7l-4.7,0.1l0.3,19.7l2.1,0c0,0,16.5,0.4,16.3-11L80.5,116.4z"
          />
          <path
            className="st0"
            d="M130.3,86.4l0,0.5c-2.5,0-6.1,1.9-10.5,12.1l-10.9,28.3l-1.7,0C91.3,93.6,90.5,92.1,90.5,92.1
		c-2.1-4.6-3-4.5-4.6-4.6l0-0.5l19.2-0.3l0,0.5c-4,0.1-0.7,6.7-0.7,6.7l9.2,19.7l5.7-14.7c1.7-4.3,0.5-11.9-6.4-11.8l0-0.5
		L130.3,86.4z"
          />
          <path
            className="st0"
            d="M168.4,115.1l0.2,11.3l-33.6,0.5l0-0.5c2.4,0,3.3-3.5,3.2-7.4l-0.4-24.7c-0.1-3.6-0.9-7.5-3.4-7.4l0-0.5
		l32.9-0.5l0.2,11.8l-0.5,0C166.7,86.5,151,86.6,151,86.6l-2.1,0l0.3,19.3l4.7-0.1c0.1,0,6.2,0.1,6.7-6.9l0.5,0l0.2,14.1l-0.5,0
		c-0.7-6.9-6.8-6.7-6.9-6.7l-4.7,0.1l0.3,19.7l2.1,0c0,0,16.5,0.4,16.3-11L168.4,115.1z"
          />
          <path
            className="st0"
            d="M206.4,113.4l0.2,12.5l-30.5,0.4l0-0.5c2.5,0,2.3-1,2.3-4.9l-0.5-31.3c-0.1-3.5-0.4-3.3-2.9-3.3l0-0.5
		l17.2-0.2l0,0.5c-2.5,0-2.9,0-2.9,4.1l0.5,35.5c5-0.1,16.2-1.5,16-12.2L206.4,113.4z"
          />
          <path
            className="st0"
            d="M254.5,105.2c0.2,11.5-8.1,20.6-20.7,20.8c-12.7,0.2-21.2-8.7-21.3-20.2c-0.2-11.4,8-21.2,20.7-21.4
		C245.8,84.2,254.3,93.8,254.5,105.2z M240.5,105.4c-0.2-15.8-0.5-20.6-7.3-20.5c-6.9,0.1-7.1,4.9-6.8,20.7
		c0.2,15.8,0.6,20,7.4,19.9C240.6,125.4,240.7,121.2,240.5,105.4z"
          />
          <path
            className="st0"
            d="M274.1,117c0.1,7.4,1.5,7.4,4.8,7.3l0,0.5l-18.9,0.3l0-0.5c2.4,0,2.6-1.1,2.6-5L262.1,90
		c-0.1-3.6-0.5-4.9-3.1-4.9l0-0.5l14.6-0.2c11.5-0.2,22.8,0.4,23,11.3c0.2,10.8-11.2,11.6-22.6,11.8L274.1,117z M273.9,107
		c8.5-0.1,9.4,0,9.3-11.1c-0.2-11.6-1.8-11.1-9.6-11L273.9,107z"
          />
          <path
            className="st0"
            d="M342.3,83.4l14.1-0.2l0,0.5c-2.8,0-2.7,0.8-2.6,4.3l0.4,30.4c0.1,3.9,0.5,4.8,3.4,4.8l0,0.5L340,124l0-0.5
		c2.7,0,2.9-1,2.9-4.9l-0.5-33.6L328,124.7l-0.5,0l-19.2-34.4l0.3,18.9c0.1,7.4,2.3,14.7,9,14.6l0,0.5l-18,0.3l0-0.5
		c6.2-0.1,8.5-7.3,8.4-14.9l-0.3-19.8c-2.3-3.9-4.2-4.8-5.8-4.9l0-0.5l16.4-0.2l14.3,26.3L342.3,83.4z"
          />
          <path
            className="st0"
            d="M395.4,111.9l0.2,11.3l-33.6,0.5l0-0.5c2.4,0,3.3-3.5,3.2-7.4L364.8,91c-0.1-3.6-0.9-7.5-3.4-7.4l0-0.5
		l32.9-0.5l0.2,11.8l-0.5,0C393.7,83.2,378,83.4,378,83.4l-2.1,0l0.3,19.3l4.7-0.1c0.1,0,6.2,0.1,6.7-6.9l0.5,0l0.2,14.1l-0.5,0
		c-0.7-6.9-6.8-6.7-6.9-6.7l-4.7,0.1l0.3,19.7l2.1,0c0,0,16.5,0.4,16.3-11L395.4,111.9z"
          />
          <path
            className="st0"
            d="M432.3,122.7l-22.2-30.6l0.3,18c0.1,4.6,0.5,12.4,8.9,12.3l0,0.5l-18.3,0.3l0-0.5c7.9-0.1,9-8,8.9-12.6
		l-0.3-18.7l-2.8-3.8c-3.5-4.5-5.8-4.4-7.3-4.5l0-0.5l18.4-0.3l20,28l-0.2-15.4c-0.1-5.4-2-12.4-8.4-12.3l0-0.5l17.4-0.3l0,0.5
		c-7.1,0.1-8.6,7.2-8.5,12.6l0.4,27.6L432.3,122.7z"
          />
          <path
            className="st0"
            d="M490.1,81.3l0.2,13l-0.5,0c-0.4-6.6-9-12.3-13.8-12.2l-0.6,0l0.5,33.5c0.1,3.9,0.4,6,2.7,6l0,0.5l-17,0.2
		l0-0.5c2.3,0,2.6-2.1,2.5-6l-0.5-33.5l-0.6,0c-4.8,0.1-13.2,6-13.4,12.6l-0.5,0l-0.2-13L490.1,81.3z"
          />
          <path
            className="st0"
            d="M4.4,26.3L46,25.8l0.2,12.5L18,38.6l0.2,11.4l26.6-0.4L45,62.2l-26.6,0.4L18.5,75l29.7-0.4l0.2,12.5L5.2,87.7
		L4.4,26.3z"
          />
          <path
            className="st0"
            d="M55.4,25.6l18.4-0.2l26.7,42.3l0.2,0L100,25l13.5-0.2l0.8,61.3l-17.7,0.2L69.4,43.1l-0.2,0l0.6,43.7l-13.5,0.2
		L55.4,25.6z"
          />
          <path
            className="st0"
            d="M135.1,36.4l-17.5,0.2l-0.2-12l48.5-0.7l0.2,12l-17.5,0.2l0.7,49.4l-13.5,0.2L135.1,36.4z"
          />
          <path
            className="st0"
            d="M170.3,24l41.7-0.6l0.2,12.5L184,36.3l0.2,11.4l26.6-0.4l0.2,12.5l-26.6,0.4l0.2,12.5l29.7-0.4l0.2,12.5
		l-43.2,0.6L170.3,24z"
          />
          <path
            className="st0"
            d="M221.3,23.2l23.7-0.3c3.1,0,6.1,0.2,8.9,0.8c2.8,0.6,5.3,1.5,7.4,2.9c2.1,1.4,3.8,3.3,5.1,5.7
		c1.3,2.4,1.9,5.4,2,8.9c0.1,4.3-1,8-3.2,11.1c-2.2,3.1-5.5,5-9.8,5.9l15.9,25.7l-16.2,0.2L242,59.8l-6.7,0.1l0.3,24.5l-13.5,0.2
		L221.3,23.2z M235.2,48.4l8-0.1c1.2,0,2.5-0.1,3.9-0.2c1.4-0.1,2.6-0.4,3.7-0.8s2-1.1,2.7-2.1c0.7-0.9,1.1-2.2,1-3.9
		c0-1.6-0.4-2.8-1-3.7c-0.6-0.9-1.5-1.6-2.5-2.1c-1-0.5-2.1-0.8-3.4-1c-1.3-0.2-2.5-0.2-3.7-0.2l-8.8,0.1L235.2,48.4z"
          />
          <path
            className="st0"
            d="M274.5,22.5l22.8-0.3c3.2,0,6.2,0.2,9,0.7c2.8,0.5,5.3,1.5,7.4,2.9c2.1,1.4,3.8,3.3,5.1,5.7
		c1.3,2.4,1.9,5.4,2,9c0,3.6-0.5,6.6-1.6,9c-1.1,2.4-2.7,4.4-4.7,5.9c-2,1.5-4.4,2.5-7.2,3.2c-2.8,0.6-5.8,1-9,1l-9.9,0.1l0.3,23.9
		l-13.5,0.2L274.5,22.5z M288.4,48.3l9-0.1c1.2,0,2.4-0.1,3.5-0.4c1.1-0.2,2.1-0.6,3-1.2c0.9-0.6,1.6-1.3,2.1-2.3s0.8-2.2,0.8-3.6
		c0-1.6-0.4-2.8-1.1-3.8c-0.7-0.9-1.7-1.7-2.8-2.2c-1.1-0.5-2.4-0.8-3.8-0.9c-1.4-0.1-2.7-0.2-4-0.2l-6.9,0.1L288.4,48.3z"
          />
          <path
            className="st0"
            d="M325.1,21.7l23.7-0.3c3.1,0,6.1,0.2,8.9,0.8c2.8,0.6,5.3,1.5,7.4,2.9c2.1,1.4,3.8,3.3,5.1,5.7
		c1.3,2.4,1.9,5.4,2,8.9c0.1,4.3-1,8-3.2,11.1c-2.2,3.1-5.5,5-9.8,5.9l15.9,25.7l-16.2,0.2l-13.2-24.3l-6.7,0.1l0.3,24.5l-13.5,0.2
		L325.1,21.7z M338.9,46.9l8-0.1c1.2,0,2.5-0.1,3.9-0.2c1.4-0.1,2.6-0.4,3.7-0.8c1.1-0.4,2-1.1,2.7-2.1c0.7-0.9,1.1-2.2,1-3.9
		c0-1.6-0.4-2.8-1-3.7c-0.6-0.9-1.5-1.6-2.5-2.1c-1-0.5-2.1-0.8-3.4-1c-1.3-0.2-2.5-0.2-3.7-0.2l-8.8,0.1L338.9,46.9z"
          />
          <path className="st0" d="M378,21l13.5-0.2l0.8,61.3l-13.5,0.2L378,21z" />
          <path
            className="st0"
            d="M429.4,35.2c-1.1-1.4-2.6-2.4-4.5-3c-1.9-0.6-3.7-0.9-5.3-0.9c-1,0-2,0.1-3,0.4c-1,0.2-2,0.6-2.9,1.1
		c-0.9,0.5-1.7,1.2-2.2,2c-0.6,0.8-0.8,1.8-0.8,3c0,1.8,0.7,3.3,2.1,4.2c1.4,1,3.2,1.8,5.3,2.4c2.1,0.7,4.4,1.3,6.8,2
		c2.4,0.7,4.7,1.6,6.8,2.9c2.1,1.2,3.9,2.9,5.3,5c1.4,2.1,2.1,5,2.2,8.5c0,3.4-0.5,6.4-1.8,8.9c-1.2,2.6-2.9,4.7-5.1,6.4
		c-2.1,1.7-4.6,3-7.5,3.8c-2.8,0.8-5.9,1.3-9,1.3c-4,0.1-7.7-0.5-11.1-1.7c-3.4-1.2-6.6-3.1-9.6-5.8l9.5-10.7
		c1.4,1.8,3.2,3.2,5.3,4.2c2.1,1,4.3,1.5,6.6,1.4c1.1,0,2.2-0.2,3.3-0.4c1.1-0.3,2.1-0.7,3-1.2c0.9-0.5,1.6-1.2,2.1-2
		c0.5-0.8,0.8-1.8,0.8-2.9c0-1.8-0.7-3.3-2.2-4.3c-1.4-1-3.2-1.9-5.4-2.7s-4.5-1.4-7-2.2c-2.5-0.7-4.8-1.7-7-2.9
		c-2.2-1.2-4-2.9-5.4-5c-1.4-2.1-2.2-4.8-2.2-8.2c0-3.3,0.6-6.2,1.8-8.7c1.3-2.5,3-4.6,5.1-6.3c2.1-1.7,4.6-3,7.4-3.9
		c2.8-0.9,5.7-1.3,8.7-1.4c3.5,0,6.8,0.4,10.1,1.3c3.2,0.9,6.2,2.5,8.8,4.8L429.4,35.2z"
          />
          <path
            className="st0"
            d="M446.1,20l41.7-0.6l0.2,12.5l-28.2,0.4l0.2,11.4l26.6-0.4l0.2,12.5l-26.6,0.4l0.2,12.5l29.7-0.4l0.2,12.5
		l-43.2,0.6L446.1,20z"
          />
          <g>
            <path
              className="st0"
              d="M35.3,14.5c0-0.4,0-0.7,0-1c0-0.1,0-0.2,0.2-0.2c0.6,0,1.3,0,1.9,0c0.1,0,0.2,0,0.2,0.2c0,3.2,0,6.3,0,9.5
			c0,1.1-0.3,2.1-1,3c-0.6,0.7-1.4,1.2-2.3,1.4c-1.7,0.5-3.3,0.1-4.8-0.9c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0.1-0.1,0.1-0.1
			c0.4-0.4,0.9-0.9,1.3-1.3c0.1-0.1,0.1-0.1,0.2,0c0.5,0.3,0.9,0.6,1.5,0.6c1,0.1,1.8-0.1,2.5-0.9c0.3-0.4,0.5-0.8,0.5-1.3
			c0-0.2,0-0.5,0-0.8c-0.1,0.1-0.1,0.1-0.1,0.1c-0.8,0.9-1.9,1.2-3.1,1.2c-1.2-0.1-2.1-0.5-2.9-1.4c-0.4-0.5-0.6-1-0.7-1.6
			c-0.1-0.6-0.1-1.1-0.1-1.7c0-0.8,0-1.5,0-2.3c0-0.9,0.3-1.8,0.9-2.5c0.4-0.5,1-0.8,1.6-1c0.7-0.2,1.5-0.2,2.3-0.1
			c0.8,0.2,1.4,0.6,1.9,1.1C35.2,14.4,35.3,14.5,35.3,14.5z M35.3,18.4c0-0.4-0.1-0.9-0.1-1.4c0-0.6-0.3-1.1-0.8-1.5
			c-0.8-0.6-2.2-0.6-3,0c-0.5,0.4-0.8,0.9-0.8,1.5c0,0.8,0,1.5,0,2.3c0,0.2,0,0.5,0.1,0.7c0.1,0.7,0.6,1.1,1.2,1.4
			c0.9,0.4,1.7,0.4,2.5-0.1c0.5-0.3,0.9-0.8,0.9-1.4C35.3,19.4,35.3,19,35.3,18.4z"
            />
            <path
              className="st0"
              d="M25.8,22.6c0,0,0,0.1-0.1,0.1c-0.6,0.6-1.3,1-2.2,1.1c-0.8,0.1-1.6,0.1-2.3-0.2c-1.2-0.4-1.9-1.2-2-2.5
			c-0.1-0.6-0.1-1.1,0.1-1.6c0.3-0.9,0.9-1.4,1.8-1.7c0.5-0.2,1-0.2,1.5-0.2c1,0,2,0,2.9,0c0.1,0,0.1,0,0.2,0c0-0.4,0-0.9,0-1.3
			c-0.1-0.5-0.4-0.8-0.8-1c-0.4-0.2-0.8-0.2-1.2-0.2c-1.1-0.1-2.1,0.2-3,0.8c0,0,0,0,0,0c-0.5-0.5-0.9-0.9-1.4-1.4
			c0.3-0.2,0.6-0.4,0.9-0.6c0.8-0.4,1.6-0.7,2.5-0.8c1.1-0.1,2.1,0,3.1,0.3c1.3,0.4,2,1.3,2.1,2.7c0,0.2,0,0.4,0,0.6
			c0,2.2,0,4.4,0,6.6c0,0.2,0,0.2-0.2,0.2c-0.6,0-1.3,0-1.9,0c-0.1,0-0.2,0-0.2-0.2C25.9,23.2,25.8,22.9,25.8,22.6
			C25.8,22.6,25.8,22.6,25.8,22.6z M23.6,21.8c0.2,0,0.6,0,1-0.1c0.6-0.1,1-0.4,1.2-1c0.1-0.4,0.1-0.8,0.1-1.2
			c0-0.1-0.1-0.1-0.2-0.1c-1,0-2,0-3,0c-0.3,0-0.7,0.1-1,0.2c-0.6,0.2-0.7,0.6-0.6,1.2c0.1,0.4,0.4,0.6,0.7,0.8
			C22.4,21.8,22.9,21.8,23.6,21.8z"
            />
            <path
              className="st0"
              d="M45.8,20.7c0.4,0.4,0.9,0.9,1.3,1.3c-0.2,0.2-0.4,0.4-0.6,0.5c-0.8,0.7-1.8,1.1-2.9,1.2
			c-1.4,0.1-2.7-0.1-3.9-1c-0.9-0.7-1.4-1.6-1.7-2.7c-0.3-1.4-0.3-2.7,0.2-4.1c0.6-1.7,1.8-2.6,3.6-2.9c0.9-0.1,1.8-0.1,2.7,0.2
			c1.5,0.4,2.4,1.4,2.8,2.9c0.2,0.9,0.3,1.8,0.2,2.7c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.2-0.2,0.2c-2.3,0-4.6,0-6.9,0
			c-0.2,0-0.2,0-0.2,0.2c0,0.6,0.3,1.2,0.9,1.5c0.5,0.4,1,0.5,1.6,0.6c1.1,0.1,2-0.2,2.9-0.8C45.6,20.9,45.7,20.8,45.8,20.7
			C45.7,20.7,45.8,20.7,45.8,20.7z M43,17.7c0.2,0,0.5,0,0.7,0c0.6,0,1.2,0,1.9,0c0.1,0,0.2,0,0.2-0.1c0-1.1-0.5-1.8-1.5-2.3
			c-0.7-0.3-1.4-0.3-2.1-0.2c-1.2,0.3-2,1.2-2,2.4c0,0.1,0,0.2,0.2,0.2C41.3,17.7,42.1,17.7,43,17.7z"
            />
            <path
              className="st0"
              d="M9.5,21.9c0.5-0.5,1-1,1.5-1.5c0.1,0.1,0.2,0.1,0.3,0.2c0.7,0.6,1.6,1,2.5,1.1c0.7,0.1,1.5,0.1,2.1-0.3
			c0.2-0.1,0.4-0.2,0.6-0.4c0.4-0.4,0.4-1.3-0.3-1.5c-0.4-0.1-0.8-0.2-1.2-0.2c-0.9-0.1-1.9-0.1-2.8-0.4c-0.6-0.2-1.2-0.5-1.6-1
			c-0.3-0.4-0.5-0.8-0.6-1.3c-0.2-1.4,0.5-2.3,1.7-2.9c0.7-0.4,1.4-0.5,2.1-0.6c1.5-0.1,2.9,0.1,4.3,0.9c0.3,0.1,0.5,0.3,0.8,0.5
			c0,0.1-0.1,0.1-0.1,0.1c-0.4,0.4-0.8,0.8-1.2,1.2c-0.1,0.1-0.1,0.1-0.2,0c-0.8-0.5-1.7-0.8-2.6-0.8c-0.7,0-1.3,0-1.9,0.3
			c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.4-0.4,0.9,0,1.3c0.3,0.2,0.7,0.4,1.1,0.4c0.6,0.1,1.3,0.1,1.9,0.2c0.9,0.1,1.9,0.3,2.6,0.9
			c0.5,0.4,0.8,0.9,0.8,1.5c0.2,1.8-0.8,3-2.3,3.6c-0.8,0.3-1.6,0.4-2.5,0.3c-1.1,0-2-0.3-3-0.7C10.5,22.8,10,22.4,9.5,21.9z"
            />
          </g>
          <path
            className="st0"
            d="M62.8,1.6c0.4,0,0.9,0,1.4,0s1,0,1.5,0.1s1,0.1,1.4,0.2c0.4,0.1,0.8,0.3,1.1,0.5c0.7,0.4,1.2,1,1.7,1.8
		c0.5,0.8,0.7,1.7,0.7,2.8c0,1.2-0.3,2.2-0.8,3c-0.5,0.8-1.3,1.5-2.2,1.9v0.1c1.2,0.3,2.2,0.9,2.9,1.8c0.7,0.9,1,2.1,1,3.4
		c0,0.8-0.1,1.6-0.4,2.3c-0.3,0.8-0.7,1.4-1.2,2c-0.5,0.6-1.2,1-1.9,1.4c-0.8,0.4-1.7,0.5-2.7,0.5h-9.8V1.6H62.8z M63.5,11
		c1.5,0,2.6-0.3,3.3-0.9s1-1.4,1-2.6c0-0.8-0.1-1.4-0.3-1.8c-0.2-0.4-0.5-0.8-0.9-1c-0.4-0.2-0.9-0.4-1.4-0.5
		C64.6,4.1,64.1,4,63.5,4h-5.4V11H63.5z M64.9,20.9c1.2,0,2.1-0.3,2.8-1c0.7-0.7,1-1.6,1-2.9c0-0.7-0.1-1.3-0.4-1.8
		c-0.2-0.5-0.6-0.8-1-1.1c-0.4-0.3-0.9-0.5-1.4-0.6c-0.5-0.1-1.1-0.2-1.6-0.2h-6.2v7.5H64.9z"
          />
          <path
            className="st0"
            d="M87.5,1.6V4H76.8V11h10v2.4h-10v7.5h10.8v2.4H74.3V1.6H87.5z"
          />
          <path
            className="st0"
            d="M104.5,1.6V4H94.3V11h9.5v2.4h-9.5v7.5h10.3v2.4H91.9V1.6H104.5z"
          />
          <path
            className="st0"
            d="M112.1,23.2V7.7h-5.6V5.7c0.7,0,1.4-0.1,2.1-0.2c0.7-0.1,1.3-0.3,1.9-0.6c0.6-0.3,1-0.7,1.4-1.2
		c0.4-0.5,0.6-1.2,0.8-2h2v21.6H112.1z"
          />
          <path
            className="st0"
            d="M118.9,5.8c0.3-1,0.7-1.8,1.3-2.6c0.6-0.7,1.3-1.3,2.2-1.7c0.9-0.4,1.9-0.6,3-0.6c0.9,0,1.8,0.1,2.6,0.4
		c0.8,0.3,1.5,0.7,2.1,1.3c0.6,0.6,1.1,1.2,1.4,2.1c0.4,0.8,0.5,1.8,0.5,2.8c0,1-0.1,1.9-0.4,2.7c-0.3,0.8-0.7,1.5-1.2,2.1
		s-1,1.2-1.7,1.7c-0.6,0.5-1.3,1-1.9,1.4c-0.7,0.4-1.3,0.9-2,1.3c-0.7,0.4-1.3,0.9-1.8,1.3c-0.6,0.5-1,1-1.4,1.5
		c-0.4,0.6-0.7,1.2-0.8,1.9h11v2.4h-14c0.1-1.4,0.3-2.5,0.7-3.5c0.4-1,0.8-1.8,1.3-2.5c0.5-0.7,1.2-1.3,1.8-1.9
		c0.7-0.5,1.4-1,2.1-1.5c0.9-0.6,1.7-1.2,2.4-1.7c0.7-0.5,1.3-1,1.7-1.5c0.5-0.5,0.8-1.1,1-1.7c0.2-0.6,0.3-1.3,0.3-2.1
		c0-0.6-0.1-1.2-0.3-1.7c-0.2-0.5-0.5-0.9-0.9-1.3c-0.4-0.4-0.8-0.6-1.3-0.8c-0.5-0.2-1-0.3-1.6-0.3c-0.7,0-1.4,0.2-1.9,0.5
		c-0.5,0.3-1,0.8-1.3,1.3c-0.3,0.5-0.6,1.1-0.7,1.8s-0.2,1.4-0.2,2.1h-2.6C118.5,7.9,118.6,6.8,118.9,5.8z"
          />
          <path
            className="st0"
            d="M139.5,10.3c0.1,0,0.2,0,0.3,0h0.5c0.5,0,1-0.1,1.5-0.3c0.5-0.2,0.9-0.4,1.3-0.7c0.4-0.3,0.6-0.7,0.9-1.2
		c0.2-0.5,0.3-1.1,0.3-1.7c0-1.3-0.3-2.3-1-2.9c-0.7-0.6-1.6-1-2.6-1c-0.7,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.9,0.7-1.2,1.1
		s-0.6,1-0.7,1.7c-0.2,0.6-0.2,1.3-0.2,2h-2.5c0-1.2,0.2-2.2,0.5-3.2c0.3-0.9,0.7-1.8,1.2-2.4c0.5-0.7,1.2-1.2,2-1.6
		c0.8-0.4,1.7-0.6,2.7-0.6c0.9,0,1.7,0.1,2.5,0.4c0.8,0.3,1.4,0.6,2,1.2c0.6,0.5,1,1.2,1.3,2c0.3,0.8,0.5,1.8,0.5,2.9
		c0,1.1-0.3,2-0.8,2.9s-1.2,1.5-2,1.9v0.1c1.3,0.3,2.2,1,2.8,2.1s0.9,2.3,0.9,3.8c0,1.2-0.2,2.3-0.6,3.2c-0.4,0.9-0.9,1.7-1.5,2.3
		c-0.6,0.6-1.4,1.1-2.3,1.4c-0.9,0.3-1.8,0.5-2.7,0.5c-1,0-2-0.2-2.9-0.5c-0.9-0.3-1.6-0.8-2.2-1.5c-0.6-0.7-1.1-1.5-1.4-2.5
		c-0.3-1-0.5-2.1-0.4-3.3h2.5c0,1.6,0.4,2.8,1.2,3.8c0.7,1,1.8,1.5,3.2,1.5c0.6,0,1.2-0.1,1.7-0.3c0.5-0.2,1-0.5,1.4-0.9
		c0.4-0.4,0.7-0.9,1-1.5c0.2-0.6,0.4-1.2,0.4-1.9c0-0.7-0.1-1.4-0.3-2c-0.2-0.6-0.5-1.1-0.9-1.5c-0.4-0.4-0.8-0.7-1.4-0.9
		c-0.5-0.2-1.1-0.3-1.7-0.3c-0.5,0-1,0-1.5,0.1v-2.4C139.3,10.3,139.4,10.3,139.5,10.3z"
          />
        </g>
      </svg>
    </Container>
  )
}

export default PageHeader
